export const validateServiceSelected = (selectedValue) => {
    if (!selectedValue.serviceSelected) {
        return { isValid: false, error: 'Service must be selected' };
    }
    return { isValid: true };
};

export const validatePaymentMethodSelected = (selectedValue) => {
    if (!selectedValue.paymentMthdSelected) {
        return { isValid: false, error: 'Payment method must be selected' };
    }
    return { isValid: true };
};

export const validateAmountPaidSelected = (selectedValue) => {
    if (!selectedValue.amountpaidSelected) {
        return { isValid: false, error: 'Amount must be especified' };
    }
    return { isValid: true };
};

// Add more validation functions as needed
