import React from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';

import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';

const NotifButtons = () => {
  return (
		<div>
      <IconButton 
        size="large" 
        aria-label="show 4 new mails" 
        color="inherit">
        <Badge badgeContent={1} color="error">
            <MailIcon />
        </Badge>
      </IconButton>
      
      <IconButton 
        size="large" 
        aria-label="show 17 new notifications" 
        color="inherit">
        <Badge badgeContent={17} color="error">
          <NotificationsIcon />
        </Badge>         
      </IconButton>

    </div>
	);
}

export default NotifButtons;