import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setNewRoute } from '../../features/route';

const images = [
	{
	  addr: '/static/images/SpectacledBear.png',
	  title: 'Temporal Manual Input',
	  width: '30%',
	  path: 'TempInput/',
	},
  {
	  url: 'https://images.unsplash.com/photo-1554224155-6726b3ff858f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1611&q=80',
	  title: 'Administration',
	  width: '30%',
	  path: 'administration/',
	},
	{
	  url: 'https://images.unsplash.com/photo-1544377193-33dcf4d68fb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80',
	  title: 'Manual input',
	  width: '30%',
	  path: 'manualInput/',
	},
	{
	  url: 'https://images.unsplash.com/photo-1600147131759-880e94a6185f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1036&q=80',
	  title: 'Auto input',
	  width: '30%',
	  path: 'autoInput/',
	},
	{
	  url: 'https://images.unsplash.com/photo-1543286386-2e659306cd6c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
	  title: 'Check services',
	  width: '30%',
	  path: 'checkServices/',
	},
	{
	  url: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80',
	  title: 'Indicators',
	  width: '30%',
	  path: 'indicators/',
	},
	{
	  url: 'https://images.unsplash.com/photo-1576341592370-3151269da47e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80',
	  title: 'Smart basket',
	  width: '30%',
	  path: 'smartBasket/',
	},
  ];

function MenuGrid() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1, p: '5%' }}>
      <Grid container spacing={2} justifyContent="center">
        {images.map((image) => (
          <Grid item xs={12} sm={6} md={4} key={image.title} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              // onClick={() => { dispatch(setNewRoute(image.path)); }}
              onClick={() => navigate(image.path)}
              sx={{
                position: 'relative',
                height: '25vh',
                width: matches ? '100%' : '85%', // Adjusts width based on screen size
                overflow: 'hidden',
                '&:hover': {
                  '& .imageBackdrop': {
                    opacity: 0.1, // Less opacity on hover
                  },
                  '& .boxTitle': {
                    transform: 'scale(1.1)', // Scale up the title for emphasis
                  },
                },
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundImage: `url(${image.url ? image.url : process.env.PUBLIC_URL + image.addr})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
              <Box
                className="imageBackdrop"
                sx={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  backgroundColor: 'black',
                  opacity: 0.4,
                  transition: 'opacity 0.5s ease',
                }}
              />
              <Typography
                component="span"
                variant="h6"
                color="white"
                className="boxTitle"
                sx={{
                  position: 'relative',
                  p: 2,
                  zIndex: 2,
                  transition: 'transform 0.5s ease',
                }}
              >
                {image.title}
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default MenuGrid;
