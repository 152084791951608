import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
// import {Chart as ChartJS} from 'chart.js/auto'
import { userData } from '../../TestData';

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
	 	legend: {
	   	position: 'top',
	 	},
	 	title: {
	      display: true,
	      text: 'Chart.js Line Chart',
	 	},
	},
};

const ServicesGraph = () => {
	
	const [userDataState, setUserDataState] = useState ({
		labels: userData.map((data) => data.year),
		datasets: [{
			label: "users Gained",
			data: userData.map((data) => data.userGain),
			backgroundColor: [
          "rgba(75,192,192,1)",
          "red",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
		}]
	});

  return (
   	<div style={{ position: 'relative', height:'100%', width:'100%', backgroundColor: '#FFFFFF', 'borderRadius': '15px'}}>
   		<Line options={options} data={userDataState} />
   	</div>
  );
}

export default ServicesGraph;