import React from 'react';
// import 'tachyons';
import Box from '@mui/material/Box';
import SelectService from '../ManualInput/SelectService.js';
import TempDataTable from './TempDataTable';
import ServicesGraph from '../ManualInput/ServicesGraph.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'

const TempInput = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box className="CheckServicesContainer" >
      <Box className="topServicesSection" >
        <Box className="topLeftSection" >
          <SelectService />
        </Box>
        {matchesSM && <Box className="topRightSection" >
          <ServicesGraph />
        </Box>}
      </Box>
      <Box className="bottomSection" >
        <TempDataTable />
      </Box>
    </Box>
  );
}
export default TempInput;

