import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'

const Scroll = (props) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box sx={{ overflowY: 'scroll', border: '1px solid black', height:  { xs: '94vh', md: '99vh' } }}>
      {props.children}
    </Box>
  );
};

export default Scroll;

// import React from 'react';

// const Scroll = (props) => {
// 	return (
// 		<div /*className="flex content-center-ns justify-center" */style={{overflowY: 'scroll', border: '1px solid black', height: '100vh'}}>
// 			{props.children}	
// 		</div>
// 	);
// };

// export default Scroll