import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useState } from 'react';


// import {
  // Button,
  // Cascader,
  // Checkbox,
  // DatePicker,
  // Form,
  // Input,
  // InputNumber,
  // Radio,
  // Select,
  // Switch,
  // TreeSelect,
// } from 'antd';
// const { RangePicker } = DatePicker;
// const { TextArea } = Input;

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const FormDisabledDemo = () => {
  const [componentDisabled, setComponentDisabled] = useState(true);

  const onFormLayoutChange = ({ disabled }) => {
    setComponentDisabled(disabled);
  };

  return (
    <List
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      layout="horizontal"
      initialValues={{
        disabled: componentDisabled,
      }}
      onValuesChange={onFormLayoutChange}
      disabled={componentDisabled}
    >
      <ListItem label="Form disabled" name="disabled" valuePropName="checked">
        <Checkbox {...label} defaultChecked />
      </ListItem>
      <ListItem label="Radio">
        <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        >
        <FormControlLabel value="female" control={<Radio />} label="Female" />
        <FormControlLabel value="male" control={<Radio />} label="Male" />
        </RadioGroup>
      </ListItem>
      <ListItem label="Input">
        <TextField 
          id="outlined-basic" 
          label="Número de comprobante"
          // key={`confNumber${clearSelected}`}
          // onChange={(event) => {
          //   setSelectedValue({...selectedValue, confirmationNmbrSelected: event.target.value});
          // }} 
          variant="outlined"
        />
      </ListItem>
      <ListItem label="Select">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={["a","b"]}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Movie" />}
        />
      </ListItem>
      <ListItem label="Autocomplete">
        <Autocomplete
          disablePortal
          // key={`Medio${clearSelected}`}
          // onChange={(event, newValue) => {
          //   setSelectedValue({...selectedValue, paymentMthdSelected: newValue});
          //   // dispatch(fetchGraphData({paymentMthdSelected: newValue}));
          // }}
          id="combo-box-demo"
          options={["a","b"]}
          renderInput={(params) => <TextField {...params} label="Medio de pago" />}
        />
      </ListItem>
      <ListItem label="Autocomplete">
        <Autocomplete
          disablePortal
          // key={`Medio${clearSelected}`}
          // onChange={(event, newValue) => {
          //   setSelectedValue({...selectedValue, paymentMthdSelected: newValue});
          //   // dispatch(fetchGraphData({paymentMthdSelected: newValue}));
          // }}
          id="combo-box-demo"
          options={["a","b"]}
          renderInput={(params) => <TextField {...params} label="Medio de pago" />}
        />
      </ListItem>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ListItem label="DatePicker">
        <DatePicker
          label="Fecha de Pago"
          value="test"
          // value={selectedValue.datePaymentSelected}
          // onChange={(newValue) => {
          //   setSelectedValue({...selectedValue, datePaymentSelected: newValue});
          //   // setDatePayment(newValue);
          // }}
          renderInput={(params) => <TextField {...params} />}
          />
      </ListItem>
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ListItem label="DatePicker">
        <DatePicker
          label="Fecha de Pago"
          value="test"
          // value={selectedValue.datePaymentSelected}
          // onChange={(newValue) => {
          //   setSelectedValue({...selectedValue, datePaymentSelected: newValue});
          //   // setDatePayment(newValue);
          // }}
          renderInput={(params) => <TextField {...params} />}
          />
      </ListItem>
      </LocalizationProvider>
      <ListItem label="InputNumber">
        <TextField 
          id="outlined-basic" 
          label="Número de comprobante"
          // key={`confNumber${clearSelected}`}
          // onChange={(event) => {
          //   setSelectedValue({...selectedValue, confirmationNmbrSelected: event.target.value});
          // }} 
          variant="outlined"
        />
      </ListItem>
      <ListItem label="TextArea">
        <TextField 
          id="outlined-basic" 
          label="Número de comprobante"
          // key={`confNumber${clearSelected}`}
          // onChange={(event) => {
          //   setSelectedValue({...selectedValue, confirmationNmbrSelected: event.target.value});
          // }} 
          variant="outlined"
        />
      </ListItem>
      <ListItem label="Switch" valuePropName="checked">
        <Switch {...label} defaultChecked />
      </ListItem>
      <ListItem label="Button">
        <Button>Button</Button>
      </ListItem>
    </List>
  );
};

export default () => <FormDisabledDemo />;