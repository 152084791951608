import axios from 'axios';

export const tempFetchAll = (user_id, token, data, endpoint, queryParameters) => {

    const HOST = process.env.REACT_APP_API_HOST;
    axios.defaults.withCredentials = true;

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${HOST + endpoint + (queryParameters ? queryParameters : '')}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios.request(config)
        .then((response) => {
            // console.log(JSON.stringify(response.data.rows));
            return response.data.rows;
        })
        .catch((error) => {
            console.log(error);
        });
}

export default tempFetchAll;