import React from 'react';
import {isMobile} from 'react-device-detect';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const FreqSelect = (props) => {
    const options = []
    for (let i = 1; i < 53; i++) {
      options.push({value:i, label: `${i} ${props.units}`})
    }

    return (
    <FormControl>
        <InputLabel id="demo-simponClick={e => this.handleChildClick(child,e)}le-select-label">Frecuencia</InputLabel>
        <Select
            id="frequency"
            label="Frequency"
            labelId="demo-simple-select-label"
            native={isMobile}
            onChange={props.onChange('frequency')}
            size="small"
            value={props.value}
        >
            {options.map(option => (
                isMobile ? (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ) : (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                )
            ))}
        </Select>
    </FormControl>
    )
}    

export default FreqSelect;