export const userData = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555,
  },
  {
    id: 5,
    year: 2020,
    userGain: 4300,
    userLost: 234,
  },
  {
    id: 6,
    year: 2021,
    userGain: 9034,
    userLost: 234,
  },
  {
    id: 7,
    year: 2022,
    userGain: 6349,
    userLost: 234,
  },
  {
    id: 8,
    year: 2023,
    userGain: 475,
    userLost: 234,
  },
  {
    id: 9,
    year: 2024,
    userGain: 22,
    userLost: 234,
  },
  {
    id: 10,
    year: 2025,
    userGain: 34732,
    userLost: 234,
  },
  {
    id: 11,
    year: 2026,
    userGain: 123,
    userLost: 234,
  },
  {
    id: 12,
    year: 2027,
    userGain: 4300,
    userLost: 234,
  },
  {
    id: 13,
    year: 2028,
    userGain: 4300,
    userLost: 234,
  },
];