import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from '../../features/isLogin';
import { isDesktop } from 'react-device-detect';

import 'tachyons';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import TestComponent from './TestComponent.js';
import AdministrationForm from './AdministrationForm.js';

import ServiceSelector from '../common/ServiceSelector.js';//test component
import PaymentMethodSelector from '../common/PaymentMethodSelector.js';//test component
import DateSelector from '../common/DateSelector.js';//test component
import AmountInputSelected from '../common/AmountInputSelected.js';//test component

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  backgroundColor: '#FFFFFF',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));

const Signup = (handleChange) => {
  //I use this component as a placeholder for future development
  return (
    //<article className="br3 ba dark-gray b--white-80 mv4 w-100 w-50-m w-25-l mw6 shadow-5 center">
    <main className="pa3 navy">
      <div className="measure">
        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
          <legend className="f1 fw6 ph0 mh0">Sign Up</legend>
          <div className="mt3">
            <label className="db fw6 lh-solid f6" htmlFor="name">Username</label>
            <input
              className="pa2 input-reset ba bg-transparent hover-bg-navy b--navy-30 hover-white w-100"
              type="text"
              name="name"
              id="name"
              onChange={(event) => { console.log(event.target.value) }}
            />
          </div>
          <div className="mt3">
            <label className="db fw6 lh-solid f6" htmlFor="email-address">Email</label>
            <input
              className="pa2 input-reset ba bg-transparent hover-bg-navy b--navy-30 hover-white w-100 ttu"
              type="email"
              name="email-address"
              id="email-address"
              onChange={(event) => { console.log(event.target.value) }}
            />
          </div>
          <div className="mv3">
            <label className="db fw6 lh-solid f6" htmlFor="password">Password</label>
            <input
              className="b pa2 input-reset ba bg-transparent hover-bg-navy b--navy-30 hover-white w-100"
              type="password"
              name="password"
              id="password"
              onChange={(event) => { console.log(event.target.value) }}
            />
          </div>
        </fieldset>
        <div className="flex ph4 justify-between">
          <div className="">
            <input
              // onClick={this.onSubmitSignIn}
              className="b ph3 pv2 b--white-80 white input-reset ba b--black bg-navy grow pointer f6 dib"
              type="submit"
              value="Sign up"
            />
          </div>
          <div className="">
            <input
              onClick={() => { handleChange() }}
              className="b ph3 pv2 b--white-80 white input-reset ba b--black bg-navy grow pointer f6 dib"
              type="submit"
              value="Back"
            />
          </div>
        </div>
      </div>
    </main>
    //</article>
  );
}

const breakpointsHeight = () => {
  let fieldHeight = '';
  (isDesktop) ? (fieldHeight = '30px') : (fieldHeight = 20);
  return fieldHeight;
};

const breakpointsWidth = () => {
  return [
    {
      xs: '100vw', // theme.breakpoints.up('xs')
      sm: '50vw', // theme.breakpoints.up('sm')
      md: '50vw', // theme.breakpoints.up('md')
      lg: '60vw', // theme.breakpoints.up('lg')
      xl: '80vw', // theme.breakpoints.up('xl')
    },
    {
      xs: 0, // theme.breakpoints.up('xs')
      sm: 0, // theme.breakpoints.up('sm')
      md: '25vw', // theme.breakpoints.up('md')
      lg: '35vw', // theme.breakpoints.up('lg')
      xl: '80vw', // theme.breakpoints.up('xl')
    },
  ]
}

const Administration = () => {

  // const userData = useSelector((state) => state.userInformation.userData[0].isLoggedIn);
  const isLoggedIn = useSelector((state) => state.userInformation.userData[0].isLoggedIn);
  const [registerModule, setRegisterModule] = useState(false);

  function handleChange(changeType) {
    setRegisterModule(!registerModule);
  };

  return (
    <div className="flex" style={{ backgroundColor: '#D8DBE2' }}>
      <Box
        // style={{ flex: 1 }}
        sx={{
          p: 1,
          bgcolor: '#D8DBE2',
          mx: "2%",
          my: 4,
          display: 'grid',
          // height: '100vh',
          width: breakpointsWidth()[0],
          // gridTemplateColumns: { md: '1fr' },
          gap: 2,
        }}
      >
        <Item key={'Formulaire'} elevation={24} sx={{
          height: '100%',
          padding: '30px',
          pb: breakpointsHeight()
          // width: breakpointsWidth()[1]
        }}>
          {/*{Signin()}*/}
          {/*<TestComponent/>*/}
          <AdministrationForm />
        </Item>
      </Box>
      {isDesktop ? (
        <Box
          // style={{ flex: 1 }}
          sx={{
            p: 1,
            // bgcolor: 'background.default',
            mx: "auto",
            // my: "auto",
            my: "30px",
            display: 'grid',
            // height: '100vh',
            bgcolor: '#D8DBE2',
            // gridTemplateColumns: { md: '1fr' },
            width: breakpointsWidth()[1],
            gap: 2,
          }}
        >
          <Item key={'Formulaire'} elevation={24} sx={{
            height: 450,
            // width: breakpointsWidth()[1]
          }}>
            {/* {Signup(handleChange)} //placeholder */}
            {/* {<ServiceSelector />} */}
            {/* {<PaymentMethodSelector />} */}
            {/* {<DateSelector />} */}
            {<AmountInputSelected />}
          </Item>
        </Box>
      ) : void 0}
    </div>
  );
}

export default Administration;


// <Box
//   className="flex items-end pa3"
//   sx={{
//   bgcolor: 'gray',
//   color: 'white',
//   width: breakpointsWidth()[0],
//   height: '100vh',
//   }}
// >
// </Box>