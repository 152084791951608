import { createSlice } from "@reduxjs/toolkit";
// import { CHANGE_ROUTE } from './constants.js';

const initialState = {
    // user_id: null, //This might not be necessary
    serviceSelectedId: null,
    serviceSelected: null,
    paymentMthdSelectedId: null,
    paymentMthdSelected: null,
    dateLimitSelected: new Date().toISOString(),
    datePaymentSelected: new Date().toISOString(),
    confirmationNmbrSelected: '',
    amountpaidSelected: null,
  };

export const optionSelectedSlice = createSlice({
	name: "optionSelected",
	initialState: initialState,
	reducers: {
		setNewOptions:(state, action) => {
      return Object.assign({}, state, action.payload);
		},
    resetOptions: () => initialState,
	}
});

export const { setNewOptions, resetOptions } = optionSelectedSlice.actions;
export default optionSelectedSlice.reducer;