import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setNewRoute, goToHome } from '../../features/route'

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
// import AppBar from '@mui/material/AppBar';
// import Icon from '@mui/material/Icon';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import MailIcon from '@mui/icons-material/Mail';


const menuOptions = [
  {text: 'Home', icon: <RocketLaunchIcon />, path: '/', },
  {text: 'Administration', icon: <LocalAirportIcon />, path: 'administration/', },
  {text: 'Manual input', icon: <SmartToyIcon />, path: 'manualInput/', },
  {text: 'Auto Input', icon: <SmartToyIcon />, path: 'autoInput/', },
  {text: 'Check Services', icon: <LocalAirportIcon />, path: 'checkServices/', },
  {text: 'Indicators', icon: <RocketLaunchIcon />, path: 'indicators/', },
  {text: 'Smart Basket', icon: <RocketLaunchIcon />, path: 'smartBasket/', },
]

const userMenuOptions = [
  {text: 'Alerts', icon: <LocalAirportIcon />, path: 'alerts/', },
  {text: 'Profile', icon: <SmartToyIcon />, path: 'profile/', },
  {text: 'My account', icon: <SmartToyIcon />, path: 'myAccount/', },
  {text: 'Sign out', icon: <MailIcon />, path: 'logout/', },
]

const MenuButton = (props) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setVisible(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250}}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}>
      <List>
        {menuOptions.map((item) => (
          // <ListItem button onClick={() => {dispatch(setNewRoute(item.path))}} key={item.text}>
          <ListItem button onClick={() => navigate(item.path)} key={item.text}>
          <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>

      <Divider />

      <List>
        {userMenuOptions.map((item) => (
          // <ListItem button onClick={() => {dispatch(goToHome())}} key={item.text}>
          <ListItem button onClick={() => navigate(item.path)} key={item.text}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
        
    </Box>
  );


	return (
		<div>
      <IconButton
           size="large"
           edge="start"
           color="inherit"
           aria-label="open drawer"
           onClick={toggleDrawer(true)}
           sx={{ mr: 2 }}>
           <MenuIcon />
       </IconButton>
      
       <Drawer
            sx={{zIndex: 'tooltip'}}
            transitionDuration={500}
            elevation={20}
            open={visible}
            onClose={toggleDrawer(false)}>
            {list()}
        </Drawer>
     </div>
	);
}

export default MenuButton;

////////////////////////

// import React, { useState } from 'react';
// import Box from '@mui/material/Box';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import Divider from '@mui/material/Divider';
// import Drawer from '@mui/material/Drawer';
// import AppBar from '@mui/material/AppBar';
// import Icon from '@mui/material/Icon';

// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
// import SmartToyIcon from '@mui/icons-material/SmartToy';
// import LocalAirportIcon from '@mui/icons-material/LocalAirport';
// import MailIcon from '@mui/icons-material/Mail';

// const menuOptions = [
//   {text: 'Home', icon: <RocketLaunchIcon />, path: '/', },
//   {text: 'Administration', icon: <LocalAirportIcon />, path: '/', },
//   {text: 'Manual input', icon: <SmartToyIcon />, path: '/', },
//   {text: 'Auto Input', icon: <SmartToyIcon />, path: '/', },
//   {text: 'Check Services', icon: <LocalAirportIcon />, path: './CheckServices/CheckServices', },
//   {text: 'Indicators', icon: <RocketLaunchIcon />, path: '/', },
//   {text: 'Smart Basket', icon: <RocketLaunchIcon />, path: '/', },
// ]

// const userMenuOptions = [
//   {text: 'Alerts', icon: <LocalAirportIcon />, path: '/', },
//   {text: 'Profile', icon: <SmartToyIcon />, path: '/', },
//   {text: 'My account', icon: <SmartToyIcon />, path: '/', },
//   {text: 'Sign out', icon: <MailIcon />, path: '/', },
// ]

// const MenuButton = (props2) => {
//   const [visible, setVisible] = useState(false);
//   // console.log('menbutton',props2);

//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setVisible(open);
//   };

//   const list = () => (
//     <Box
//       sx={{ width: 250}}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}>
//       <List>
//         {menuOptions.map((item) => (
//           <ListItem button   onClick={()=>props2.RouteChange('home')} key={item.text}>
//             <ListItemIcon>{item.icon}</ListItemIcon>
//             <ListItemText primary={item.text} />
//           </ListItem>
//         ))}
//       </List>

//       <Divider />

//       <List>
//         {userMenuOptions.map((item) => (
//           <ListItem button key={item.text}>
//             <ListItemIcon>{item.icon}</ListItemIcon>
//             <ListItemText primary={item.text} />
//           </ListItem>
//         ))}
//       </List>
        
//     </Box>
//   );


//   return (
//     <div>
//       <IconButton
//            size="large"
//            edge="start"
//            color="inherit"
//            aria-label="open drawer"
//            onClick={toggleDrawer(true)}
//            sx={{ mr: 2 }}>
//            <MenuIcon />
//        </IconButton>
      
//        <Drawer
//             sx={{zIndex: 'tooltip'}}
//             transitionDuration={500}
//             elevation={20}
//             open={visible}
//             onClose={toggleDrawer(false)}>
//             {list()}
//         </Drawer>
//      </div>
//   );
// }

// export default MenuButton;