import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery, useTheme } from '@mui/material';

import ServiceSelector from '../common/ServiceSelector.js';
import PaymentMethodSelector from '../common/PaymentMethodSelector.js';
import DateSelector from '../common/DateSelector.js';
import AmountInputSelected from '../common/AmountInputSelected.js';
import TextInputSelected from '../common/TextInputSelected.js';
import { validateServiceSelected, validatePaymentMethodSelected, validateAmountPaidSelected } from '../../helpers/formValidation';
import { sendToDB } from '../../helpers/sendToDB';
import { resetOptions } from '../../features/optionSelected';

import SButtons from '../CommonComponents/SButtons'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import CloudQueueTwoToneIcon from '@mui/icons-material/CloudQueueTwoTone';
import WashIcon from '@mui/icons-material/Wash';
import PetsIcon from '@mui/icons-material/Pets';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const icons = [
  <ElectricalServicesIcon sx={{ fontSize: '8vmax' }} />,
  <PropaneTankIcon sx={{ fontSize: '8vmax' }} />,
  <CloudQueueTwoToneIcon sx={{ fontSize: '8vmax' }} />,
  <WashIcon sx={{ fontSize: '8vmax' }} />,
  <PetsIcon sx={{ fontSize: '8vmax' }} />,
  <DirectionsCarIcon sx={{ fontSize: '8vmax' }} />
];

// const initialState = {
//   user_id: null,
//   serviceSelectedId: null,
//   serviceSelected: null,
//   paymentMthdSelectedId: null,
//   paymentMthdSelected: null,
//   dateLimitSelected: new Date(),
//   datePaymentSelected: new Date(),
//   confirmationNmbrSelected: '',
//   amountpaidSelected: '',
// };


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SelectService = () => {

  // const [selectedValue, setSelectedValue] = useState(initialState);
  const [selectedValue, setSelectedValue] = useState(useSelector((state) => state.optionSelected));
  const { user_id, token } = useSelector((state) => state.userInformation.userData[0]);
  const selectedState = useSelector((state) => state.optionSelected);

  const [open, setOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('warning');
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  // const [formError, setFormError] = useState({ serviceSelected: false, paymentMthdSelected: false, amountpaidSelected: false });
  const dispatch = useDispatch();

  const setSnackbar = (props) => {

    const alertOptions = {
      "Bad Request": ['error', 'The record could not be saved! - Bad Request'],
      warning: ['warning', 'All fields have been cleared!'],
      info: ['info', 'This is an information message!'],
      Created: ['success', 'The record was updated successfully!!'],
    }
    return (
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertOptions[props][0]} sx={{ width: '100%' }}>{alertOptions[props][1]}</Alert>
      </Snackbar>
    );
  }

  // const handleClick = () => {
  //   //Function is not being used right now
  //   setOpen(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // const handleChange = (prop) => (event) => {
  //   setSelectedValue({ ...selectedValue, user_id: user_id, [prop]: event.target.value });
  // };
  
  function handleButton(changeType) {
    switch (changeType) {
      case 'SAVE':
        const { isValid: isServiceValid, error: serviceError } = validateServiceSelected(selectedState);
        const { isValid: isPaymentMethodValid, error: paymentMethodError } = validatePaymentMethodSelected(selectedState);
        const { isValid: isAmountPaidValid, error: amountPaidError } = validateAmountPaidSelected(selectedState);

        if (!isServiceValid || !isPaymentMethodValid || !isAmountPaidValid) {
          console.error('There is an error with the form');
          console.error(isServiceValid, serviceError, isPaymentMethodValid, paymentMethodError, isAmountPaidValid, amountPaidError);
          setOpen(true);
          break;
        }
        // setSelectedValue(initialState);
        sendToDB(user_id, token, selectedState, "/test");
        break;

      case 'CANCEL':
        //setSelectedValue(initialState);
        setOpen(true);
        setSubmitStatus('warning');
        dispatch(resetOptions());
        break;

      case 'TEST':
        // const isBadForm = validate();
        // const { isValid: isServiceValid, error: serviceError } = validateServiceSelected(selectedValue);
        // const { isValid: isPaymentMethodValid, error: paymentMethodError } = validatePaymentMethodSelected(selectedValue);
        // const { isValid: isAmountPaidValid, error: amountPaidError } = validateAmountPaidSelected(selectedValue);

        // if (!isServiceValid || !isPaymentMethodValid || !isAmountPaidValid) {
        //   setOpen(true);
        //   console.error('There is an error with the form');
        //   break;
        // }
        // console.log('The form is OK');
        break;
      default:
        console.log('There is an error with the option selected');
    }
  };

  return (
    <div id="create-course-form">

      <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ margin: '0.8em' }}>
        <Grid item sm={12} md={12}>
          <Typography variant="h5" sx={{ textAlign: 'center' }}>
            Select a service to update
          </Typography>
        </Grid>
        <Grid item sm={12} md={12}>
          <ServiceSelector />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 2, md: 3 }} columns={{ sm: 8, md: 12 }} sx={{ pb: '0.5em' }}>
        <div>
          {isLargeScreen && <Grid item md={4}> {icons[0]} </Grid>}
        </div>

        <Grid item xs={11} sm={12} md={8}>
          <PaymentMethodSelector />

          <Grid container spacing={2} sx={{ pt: '0.8em' }}>
            <Grid item xs={11} md={6}>
              <DateSelector dateType="deadline" />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateSelector dateType="payment" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInputSelected />
            </Grid>
            <Grid item xs={12} md={6}>
              <AmountInputSelected />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SButtons buttonOptions={['SAVE', 'CANCEL', 'TEST']} handleButton={handleButton} />
      {setSnackbar(submitStatus)}
    </div >
  );
}

export default SelectService;

{/* <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid> //for reference */ }