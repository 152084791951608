import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { userData } from '../../TestData';

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
	 	legend: {
	 		display: false,
	   	position: 'top',
	 	},
	 	title: {
	      display: false,
	      text: 'https://react-chartjs-2.js.org/components/polar-area',
	 	},
	},
};

const userDataState = (props) => {
	const data = {
		labels: props.map((data) => data.year),
		datasets: [{
			label: "users Gained",
			data: props.map((data) => data.userGain),
			backgroundColor: [
          "rgba(75,192,192,1)",
          "red",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
		}]
	};
	return data;
};

const DoughnutChart = () => {

  return (
   	<div style={{ position: 'relative', height:'100%', width:'100%', backgroundColor: '#FFFFFF', 'borderRadius': '15px'}}>
   		<Doughnut data={userDataState(userData)} options={options}  />
   	</div>
  );
}

export default DoughnutChart;