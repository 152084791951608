import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosRequestHelper from "../helpers/axiosRequestHelper";

const initialState = {
	loading: false,
	graphParameters: [{ "service_paid": '' }],
	error: '',
}

//create createAsyncThunk generates pending, fulfilled and rejected action types
export const fetchGraphData = createAsyncThunk('graphData/fetchGraphData', (dataInfo) => {

	let data = { id: dataInfo }

	return axiosRequestHelper('post', data, '/graphData', null)
		.then(response => { return response })
})

const graphDataSlice = createSlice({
	name: "graphData",
	initialState: initialState,
	extraReducers: builder => {
		builder.addCase(fetchGraphData.pending, state => {
			state.loading = true
		})
		builder.addCase(fetchGraphData.fulfilled, (state, action) => {
			state.loading = false
			state.graphParameters = action.payload
			state.error = ''
		})
		builder.addCase(fetchGraphData.rejected, (state, action) => {
			state.loading = false
			state.graphParameters = []
			state.error = action.error.message
		})
	}
});

// export const { changeGraph } = graphDataSlice.actions;
export default graphDataSlice.reducer;