import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import axiosRequestHelper from '../../helpers/axiosRequestHelper';
import tempFetchAll from '../../helpers/tempFetchAll';


function createData(row) {
  // Extract and format the date and amount fields
  const paymentDeadline = new Date(row.payment_deadline).toLocaleDateString();
  const paymentDate = new Date(row.payment_date).toLocaleDateString();
  const amountPaid = row.amount_paid ? row.amount_paid.toLocaleString() : 'N/A';

  // Return the data structure that matches your table columns
  return {
    service_paid: row.service_paid,
    amount_paid: amountPaid,
    payment_method: row.payment_mthd_id, // Replace with your actual method of determining the payment method
    payment_deadline: paymentDeadline,
    payment_date: paymentDate,
    confirmation_num: row.confirmation_num || 'N/A' // Use 'N/A' if confirmation_num is not available
  };
}

export default function CustomizedTables() {
  const { user_id, token } = useSelector((state) => state.userInformation.userData[0]);
  const [rows, setRows] = useState([]); // this is the state that will hold the data for the table rows (initialized with the data from sampleData.json) using the createData function it will format the data to match the table columns
  // const [rows, setRows] = useState(initialData.map(createData)); // this is the state that will hold the data for the table rows (initialized with the data from sampleData.json) using the createData function it will format the data to match the table columns
  const [update, triggerUpdate] = useState([]); // this is the state that will hold the data for the table rows (initialized with the data from sampleData.json) using the createData function it will format the data to match the table columns

  useEffect(() => {
    // This is an IIFE (Immediately Invoked Function Expression)
    // It's an async function that gets executed immediately
    (async () => {
      try {
        const fetchedPayments = await tempFetchAll(user_id, token, null, '/tempSelectAll');
        // const fetchedPayments = await axiosRequestHelper('get', user_id, '/tempSelectAll', null);
        if (Array.isArray(fetchedPayments)) { setRows(fetchedPayments.map(createData)); }
      } catch (error) {
        console.error('Failed to fetch Payment Methods:', error);
        //Error Handling: Right now, if there's an error in fetching the services list, it's logged to the console. 
        //Depending on your application, you might want to handle this error in a user-friendly way, such as showing a message to the user or retrying the request.
      }
    })();
  }, [update]); //[user_id, token]); // Empty dependency array means this effect runs once after the initial render


  const handleRefresh = () => {
    // Placeholder for fetching new data logic
    // Here you would fetch the new data and update the state
    // For the demonstration, we are just resetting the rows to the initial data
    // setRows(fetchedPayments.map(createData));
    triggerUpdate([1]);
  };

  return (
    <>
      <Tooltip title="Refresh">
        <IconButton onClick={handleRefresh} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
      </Tooltip>
      <TableContainer component={Paper} style={{ maxHeight: '400px', overflow: 'auto' }}>
        <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell>Service Paid</TableCell>
              <TableCell align="right">Amount Paid</TableCell>
              <TableCell align="right">Payment Method</TableCell>
              <TableCell align="right">Payment Deadline</TableCell>
              <TableCell align="right">Payment Date</TableCell>
              <TableCell align="right">Confirmation Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.service_paid}
                </TableCell>
                <TableCell align="right">{row.amount_paid}</TableCell>
                <TableCell align="right">{row.payment_method}</TableCell>
                <TableCell align="right">{row.payment_deadline}</TableCell>
                <TableCell align="right">{row.payment_date}</TableCell>
                <TableCell align="right">{row.confirmation_num}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}