import React, { useState, useEffect } from 'react';
import { PolarArea } from 'react-chartjs-2';
// import {Chart as ChartJS} from 'chart.js/auto'
// import { userData } from '../../TestData';
import { TestData } from '../../TestData2';
// import { useSelector } from "react-redux";

export const options = {
	responsive: true,
	maintainAspectRatio: true,
	plugins: {
	 	legend: {
	   	position: 'top',
	 	},
	 	title: {
	      display: false,
	      text: 'https://react-chartjs-2.js.org/components/polar-area',
	 	},
	},
};

const PolarChart = () => {
	// const userData = useSelector((state) => state.indicatorData.indicatorData);
	const [userData, setUserData] = useState (TestData);

	useEffect(()=>{ 
   	const fetchData = async () => {
    		const data = await fetch(`http://localhost:3000/indicators`);
    		const json = await data.json();
    		setUserData(json);
				// .then( response => response.json())
				// .then(dataReceived => {
				//   console.log('dataReceived',dataReceived);
				//   setUserData(dataReceived);
				// });
  		};
		
		fetchData()
		.catch(console.error);
  	}, []);

	const [userDataState, setUserDataState] = useState ({
		labels: userData.map((data) => {console.log('data',data); return data.service_paid}),
		redraw: Object.keys(userData).length,
		datasets: [{
			label: "users Gained",
			data: userData.map((data) => data.amount_paid),
			backgroundColor: [
          "rgba(75,192,192,1)",
          "red",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
		}]
	});

  return (
   	<div key={userDataState.redraw} style={{ position: 'relative', height:'100%', width:'100%', backgroundColor: '#FFFFFF', 'borderRadius': '15px'}}>
   		<PolarArea data={userDataState} options={options}  />
   	</div>
  );
}

export default PolarChart;

{/*<PolarArea data={userDataState} options={options}  />*/}