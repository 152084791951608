import React from 'react';
import './App.css';
import { useMediaQuery, useTheme } from '@mui/material';
import { createBrowserRouter, RouterProvider, Navigate, redirect } from "react-router-dom";

import Root from "./components/Root/root.js";
import ErrorPage from "./components/common/error-page.js";
// import AuthGuard from './components/common/AuthGuard.js';
// import { AuthGuard } from './helpers/AuthGuard.js';

import IndicatorBar from './components/IndicatorBar/IndicatorBar';
import MenuGrid from './components/MenuGrid/MenuGrid';

import CheckServices from './components/CheckServices/CheckServices';
import ManualInput from './components/ManualInput/ManualInput';
import Indicators from './components/Indicators/Indicators';
import Login from './components/Login/Login';
import Administration from './components/Administration/Administration';
// import MyTest from './MyTest';
import TempInput from './components/TempInput/TempInput';

import { useSelector }  from "react-redux";

import LogoutHelper from './helpers/LogoutHelper';

function App(props) {
  const route = useSelector((state) => state.routes.route);
  const isLoggedIn = useSelector((state) => state.userInformation.userData[0].isLoggedIn);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  // const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const protectedLoader = (deafultProps) => {
    if(!isLoggedIn){
      // let params = new URLSearchParams(window.location.search);
      let params = new URLSearchParams();
      params.set('from', new URL(deafultProps.request.url).pathname);
      return redirect(`/login/?${params.toString()}`);
    }
    return null;
  }
  
  const newRouter = createBrowserRouter([
    {
      path: "/",
      element: isLoggedIn ? <Root /> : <Navigate replace to={"login/"} />,
      errorElement: <ErrorPage />,
      // loader: protectedLoader,
      children: [
        {
          index: true,
          element: <>{isLargeScreen && <IndicatorBar />}<MenuGrid /></>,
        },
        // {
        //   path: "menu/",
        //   element: <>{isLargeScreen && <IndicatorBar />}<MenuGrid /></>,
        // },
        {
          path: "/administration",
          loader: protectedLoader,
          element: <Administration />,
        },
        {
          path: "/ManualInput",
          element: <ManualInput />,
        },
        {
          path: "/autoInput",
          element: <ManualInput />,
        },
        {
          path: "/checkServices",
          element: <CheckServices />,
        },
        {
          path: "/indicators",
          element: <Indicators />,
        },
        {
          path: "/smartBasket",
          element: <Login />,
        },
        {
          path: "/TempInput",
          element: <TempInput />,
          // element: <AuthGuard><TempInput /></AuthGuard>,
        },
      ],
    },
    {
      path: "/login",
      element: isLoggedIn ? <Navigate replace to={"/"}/> : <Login />,
    },
    {
      path: "/logout",
      element: <LogoutHelper />,
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={newRouter} fallbackElement={<p>Initial Load...</p>} />
    </div>
  )
}

export default App;