import React, { useState } from 'react';
import { Radar } from 'react-chartjs-2';
// import {Chart as ChartJS} from 'chart.js/auto'
import { userData } from '../../TestData';

export const options = {
	responsive: true,
	maintainAspectRatio: true,
	plugins: {
	 	legend: {
	   	position: 'top',
	 	},
	 	title: {
	      display: false,
	      text: 'https://react-chartjs-2.js.org/components/polar-area',
	 	},
	},
};

const RadarChart = () => {
	
	const [userDataState, setUserDataState] = useState ({
		labels: userData.map((data) => data.year),
		datasets: [{
			label: "users Gained",
			data: userData.map((data) => data.userGain),
			backgroundColor: [
          "rgba(75,192,192,1)",
          "red",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
		}]
	});

  return (
   	<div style={{ position: 'relative', height:'100%', width:'100%', backgroundColor: '#FFFFFF', 'borderRadius': '15px'}}>
   		<Radar data={userDataState} options={options}  />
   	</div>
  );
}

export default RadarChart;

{/*<PolarArea data={userDataState} options={options}  />*/}