import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import ServicesGraph from './ServicesGraph';
import PolarChart from './PolarChart';
import RadarChart from './RadarChart';
import DoughnutChart from './DoughnutChart';
import ServicesGraphTest from './ServicesGraphTest';


const Indicators = (props) => {
	return (
		<Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%'
          }}
        >
	          <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 4
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
        >
          <Grid
            item
            xl={2.3}
            lg={2.3}
            sm={5}
            xs={8}
          >
            <PolarChart />
          </Grid>
          <Grid
            item
            xl={2.3}
            lg={2.3}
            sm={5}
            xs={8}
          >
            <DoughnutChart />
          </Grid>
          <Grid
            item
            xl={2.3}
            lg={2.3}
            sm={5}
            xs={8}
          >
            <RadarChart />
          </Grid>
          <Grid
            item
            xl={2.3}
            lg={2.3}
            sm={5}
            xs={8}
          >
            <RadarChart />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
            sx={{ height: '50vh' }}
          >
            <ServicesGraphTest />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <ServicesGraph />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
            sx={{ height: '60vh', pb: 10 }}
          >
            <DoughnutChart />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
            sx={{ height: '60vh', pb: 10}}
          >
            <ServicesGraph />
          </Grid>
        </Grid>
      </Container>
    </Box>
        </Box>
  	);
}
export default Indicators;