import * as React from 'react';
import 'tachyons';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import CloudQueueTwoToneIcon from '@mui/icons-material/CloudQueueTwoTone';
import WashIcon from '@mui/icons-material/Wash';
import PetsIcon from '@mui/icons-material/Pets';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const serv = [
  {
    id:0,
    serName:"Energía",
    "dueDate":'15/01/22',
    "amount": '$110.000'
  },
  {
    id:1,
    serName:"Gas",
    "dueDate":'15/01/22',
    "amount":120000
  },
  {
    id:2,
    serName:"Internet",
    "dueDate":"15/01/22",
    "amount":130000
  },
  {
    id:3,
    serName:"Acueducto",
    "dueDate":"15/01/22",
    "amount":140000
  },
  {
    id:4,
    serName:"Mascota",
    "dueDate":"15/01/22",
    "amount":150000
  },
  {
    id:5,
    serName:"Carro",
    "dueDate":"15/01/22",
    "amount":160000
  }]

const icons = [
  <ElectricalServicesIcon sx={{ fontSize: '2vmax'}}/>,
  <PropaneTankIcon sx={{ fontSize: '2vmax'}}/>,
  <CloudQueueTwoToneIcon sx={{ fontSize: '2vmax'}}/>,
  <WashIcon sx={{ fontSize: '2vmax'}}/>,
  <PetsIcon sx={{ fontSize: '2vmax'}}/>,
  <DirectionsCarIcon sx={{ fontSize: '2vmax'}}/>
];

const SuggestionCards = (props) => {
  return (
      <div className = 'flex flex-wrap'>{
        serv.map((servItem, i) => {
          {return(
            <List key={`cards${i}`} sx={{ flexWrap: 'wrap', width: '40%', maxWidth: 300, bgcolor: 'background.paper' }}>
              <ListItem key={`card${i}`} alignItems="flex-start">
                <ListItemIcon>
                  {icons[servItem.id]}
                </ListItemIcon>
                <ListItemText
                  primary={servItem.serName}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="div"
                        variant="body2"
                        color="text.secondary">
                      {servItem.dueDate}
                      </Typography>
                      <Typography
                        component="div"
                        variant="h6"
                        color="text.primary">
                      {servItem.amount}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider key={`divider${i}`} variant="inset" component="li" />
            </List>
          );}
        })
      }</div>
  );
}

export default SuggestionCards;

// const SuggestionCards = (props) => {
//   return (
//     <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
//       <ListItem alignItems="flex-start">
//         <ListItemIcon>
//           {icons[1]}
//         </ListItemIcon>
//         <ListItemText
//           primary={serv[1].serName}
//           secondary={
//             <React.Fragment>
//               <Typography
//                 sx={{ display: 'inline' }}
//                 component="span"
//                 variant="body2"
//                 color="text.primary"
//               >
//                 {serv[1].dueDate}
//               </Typography>
//               {serv[1].amount}
//             </React.Fragment>
//           }
//         />
//       </ListItem>
//       <Divider variant="inset" component="li" />
//       <ListItem alignItems="flex-start">
//         <ListItemIcon>
//           {icons[1]}
//         </ListItemIcon>
//         <ListItemText
//           primary={serv[1].serName}
//           secondary={
//             <React.Fragment>
//               <Typography
//                 sx={{ display: 'inline' }}
//                 component="span"
//                 variant="body2"
//                 color="text.primary"
//               >
//                 {serv[1].dueDate}
//               </Typography>
//               {serv[1].amount}
//             </React.Fragment>
//           }
//         />
//       </ListItem>
//       <Divider variant="inset" component="li" />
//       <ListItem alignItems="flex-start">
//         <ListItemIcon>
//           {icons[1]}
//         </ListItemIcon>
//         <ListItemText
//           primary={serv[1].serName}
//           secondary={
//             <React.Fragment>
//               <Typography
//                 sx={{ display: 'inline' }}
//                 component="span"
//                 variant="body2"
//                 color="text.primary"
//               >
//                 {serv[1].dueDate}
//               </Typography>
//               {serv[1].amount}
//             </React.Fragment>
//           }
//         />
//       </ListItem>
//     </List>
//   );
// }

