import axios from 'axios';

export const axiosRequestHelper = (method, data, endpoint, queryParameters) => {

    const HOST = process.env.REACT_APP_API_HOST;
    axios.defaults.withCredentials = true;

    axios.interceptors.response.use(response => response, error => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest?._retry) {
            originalRequest._retry = true;
            return axios.post(`${HOST +'/refresh'}`)
                .then(res => {
                    if (res.status === 200) {
                        return axios(originalRequest);
                    }
                }).catch(refreshError => {
                    console.error('Token refresh failed:', refreshError);
                    // Optionally handle refresh failure (e.g., by redirecting to login)
                    return Promise.reject(refreshError);
                  });
        }
        return Promise.reject(error);
    });

    let config = {
        method: method,
        maxBodyLength: Infinity,
        url: `${HOST + endpoint + (queryParameters ? queryParameters : '')}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios.request(config)
        .then((response) => {
            // console.log('Axios Helper ', JSON.stringify(response.data));
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export default axiosRequestHelper;