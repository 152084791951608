export const fetchServicesList = (user_id, token) => {
    const HOST = process.env.REACT_APP_API_HOST;
    return fetch(`${HOST}/ServicesList/${user_id}`, {
        method: 'GET',
        credentials: "include",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            'redirect': 'follow'
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .catch(error => {
            console.error('There has been a problem with your fetch operation:', error);
        });
}

export default fetchServicesList;