import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosRequestHelper from "../helpers/axiosRequestHelper";

const initialState = {
	loading: true,
	userData: [{
		"isLoggedIn": false, //Set to true to Bypass login for testing purposes
		"token": '',
		"user_id": '', // Set to '123' to Bypass login for testing purposes
		"active_status": '',
		"username": '',
		"email": '',
		"name": '',
		"lastname": '',
		"bith_date": '',
		"montly_income": '',
		"annual_income": '',
		"picture": '',
		"last_login": '',
		"register_date": '',
		"preferred_language": '',
		"phone": '',
	}],
	error: '',
}

//create createAsyncThunk generates pending, fulfilled and rejected action types
export const fetchUserData = createAsyncThunk('userData/fetchUserData', (dataInfo) => {

	let data = {
		email: dataInfo.email,
		password: dataInfo.password,
	};
	return axiosRequestHelper('post', data, '/login', null)
		.then(() => {
			let data = {
				email: dataInfo.email
			};
			return axiosRequestHelper('post', data, '/userData', null)
				.then((response) => {
					return response;
				})
		})
});

export const logout = createAsyncThunk('userData/logout', async () => {
	// Perform logout logic here, like clearing the token from local storage
	return Promise.resolve();
});

const userDataSlice = createSlice({
	name: "userData",
	initialState: initialState,
	extraReducers: builder => {
		builder.addCase(fetchUserData.pending, state => {
			state.loading = true
		})
		builder.addCase(fetchUserData.fulfilled, (state, action) => {
			state.loading = false
			state.userData = action.payload
			Object.assign(state.userData[0], { isLoggedIn: true }) //Object.assign is used to update the state, the first argument is the target object, the second argument is the source object
			state.error = ''
		})
		builder.addCase(fetchUserData.rejected, (state, action) => {
			state.loading = false
			state.userData = []
			state.error = action.error.message
		})
		builder.addCase(logout.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(logout.fulfilled, (state) => {
			// Reset the state to its initial state
			state.loading = initialState.loading;
			state.userData = initialState.userData;
			state.error = initialState.error;
		});
		builder.addCase(logout.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message;
		});
	}
});

export default userDataSlice.reducer;