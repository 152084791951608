import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import CloudQueueTwoToneIcon from '@mui/icons-material/CloudQueueTwoTone';
import WashIcon from '@mui/icons-material/Wash';
import PetsIcon from '@mui/icons-material/Pets';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const serv = [
  {
    id:0,
    serName:"Energía",
    "dueDate":'15/01/22',
    "amount": '$110.000'
  },
  {
    id:1,
    serName:"Gas",
    "dueDate":'15/01/22',
    "amount":120000
  },
  {
    id:2,
    serName:"Internet",
    "dueDate":"15/01/22",
    "amount":130000
  },
  {
    id:3,
    serName:"Acueducto",
    "dueDate":"15/01/22",
    "amount":140000
  },
  {
    id:4,
    serName:"Mascota",
    "dueDate":"15/01/22",
    "amount":150000
  },
  {
    id:5,
    serName:"Carro",
    "dueDate":"15/01/22",
    "amount":160000
  }]

const icons = [
  <ElectricalServicesIcon sx={{ fontSize: '2vmax'}}/>,
  <PropaneTankIcon sx={{ fontSize: '2vmax'}}/>,
  <CloudQueueTwoToneIcon sx={{ fontSize: '2vmax'}}/>,
  <WashIcon sx={{ fontSize: '2vmax'}}/>,
  <PetsIcon sx={{ fontSize: '2vmax'}}/>,
  <DirectionsCarIcon sx={{ fontSize: '2vmax'}}/>
];

const SuggestionCards = (props) => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={2} sx={{ p: 2, backgroundColor: '#FFFFFF', borderRadius: '15px' }}>
      {serv.map((servItem, i) => (
        <Grid item key={`card-grid-${i}`} xs={12} sm={4} md={4} lg={4}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                {icons[servItem.id]}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant={matchesSM ? "subtitle2" : "h7"}>
                    {servItem.serName}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography
                      component="div"
                      variant={matchesSM ? "body2" : "body1"}
                      color="text.secondary"
                    >
                      {servItem.dueDate}
                    </Typography>
                    <Typography
                      component="div"
                      variant={matchesSM ? "body2" : "body1"}
                      color="text.secondary"
                    >
                      {`Amount: ${servItem.amount}`}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </List>
        </Grid>
      ))}
    </Grid>
  );
}

export default SuggestionCards;