import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosRequestHelper from "../helpers/axiosRequestHelper";

const initialState = {
	loading: false,
	indicatorData: [{ "service_paid": '' }],
	error: '',
}

//create createAsyncThunk generates pending, fulfilled and rejected action types
export const fetchIndicatorData = createAsyncThunk('indicators/fetchIndicatorData', (dataInfo) => {

	return axiosRequestHelper('get', dataInfo, '/lindicatorsogin', null)
		.then(response => { return response })
})

const indicatorsSlice = createSlice({
	name: "indicators",
	initialState: initialState,
	extraReducers: builder => {
		builder.addCase(fetchIndicatorData.pending, state => {
			state.loading = true
		})
		builder.addCase(fetchIndicatorData.fulfilled, (state, action) => {
			state.loading = false
			state.indicatorData = action.payload
			state.error = ''
		})
		builder.addCase(fetchIndicatorData.rejected, (state, action) => {
			state.loading = false
			state.indicatorData = []
			state.error = action.error.message
		})
	}
});

export default indicatorsSlice.reducer;