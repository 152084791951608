import React from 'react';
// import 'tachyons';
import Box from '@mui/material/Box';
import SelectService from './SelectService';
import SuggestionCards from './SuggestionCards';
import ServicesGraph from './ServicesGraph';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'

const ManualInput = () => {
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box className="CheckServicesContainer">
    <Box className="topServicesSection">
      <Box className="topLeftSection" >
        <SelectService />
      </Box>
      {matchesSM && <Box className="topRightSection" >
        <SuggestionCards />
      </Box>}
    </Box>
    <Box className="bottomSection" >
      <ServicesGraph />
    </Box>
  </Box>
  );
}
export default ManualInput;

