import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import 'tachyons';
import SButtons from '../CommonComponents/SButtons';
import FreqSelect from '../CommonComponents/FreqSelect';
import {isMobile} from 'react-device-detect';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const breakpointsWidth = () => {
  let fieldWidth = '';
  (isMobile) ? (fieldWidth = '100%') : (fieldWidth = '400px');
  return fieldWidth;
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
}));

const initialState = {
  name: '',
  advancePayment: false,
  clientNumber: '',
  paymentNumber: '',
  contractType: '',
  periodicity: '',
  frequency: 1,
  datePaymentStart: new Date(),
};

const AdministrationForm = () => {
  const [selectedValues, setSelectedValues] = useState(initialState);
  const [open, setOpen] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('Bad Request');
  const [formError, setFormError] = useState({name: false});
  //REEMPLAZAR LAS SIGUIENTES DOS LINEAS CUANDO SE ACTIVE EL INICIO DE SESIÓN.
  const userId = 1;
  // const userId = useSelector((state) => state.userInformation.userData[0].user_id);

  const validate = values => {
    let isFormError = formError;
    if (!selectedValues.name) {
      isFormError = { ...isFormError, name: true};
      setFormError(formError => {return { ...formError, name: true}});
    } else {
      isFormError = { ...isFormError, name: false};
      setFormError(formError => {return { ...formError, name: false}});
    };
    const isBadForm = Object.values(isFormError).includes(true);
    return isBadForm;
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const setSnackbar = (props) => {

    const alertOptions = {
      "Bad Request": ['error','The record could not be saved! - Bad Request'], 
      warning: ['warning','All fields have been cleared!'], 
      info: ['info','Some information is missing!'], 
      Created: ['success','The record was updated successfully!!'], 
    }
    return (
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertOptions[props][0]} sx={{ width: '100%' }}>{alertOptions[props][1]}</Alert>
      </Snackbar>
    );
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = (prop) => (event) => {
    setSelectedValues({ ...selectedValues, userId: userId, [prop]: event.target.value });
  };

  function handleButton(changeType) {
    switch (changeType){
      case 'SAVE':
        setSelectedValues({...selectedValues, userId: userId});
        if(validate()) {
          setOpen(true);
          setSubmitStatus('info');
          break;
        }
        fetch('http://localhost:3000/newServiceRegistration', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(selectedValues),
        })
        .then(response => {
          setSubmitStatus('info');
          setOpen(true);
          // setSubmitStatus(response.statusText);
        });
        // setSelectedValues(initialState);

        break;
      case 'CANCEL':
        // setSelectedValue(initialState);
        // setSelectedValue(Object.keys(selectedValue).forEach(key => {selectedValue[key] = null;}));
        // setSelectedValue({...selectedValue, dateLimitSelected: null});
        // setSelectedValue({...selectedValue, datePaymentSelected: null});
        break;
      default:
        console.log('There is an error with the option selected');
    }
  };

  return (
    <div>
      <fieldset id="register_new" className="ba b--transparent ph0 ma0">
        <legend className="measure navy f2 fw6">Register a new Service</legend>
      </fieldset>
      
      <Box sx={{display: 'flex'}}>
          <Grid container spacing={'3%'}>

            <Grid item sx={{width: breakpointsWidth()}}>
              <FormControl fullWidth sx={{ m: 0}}>
                <TextField 
                  id="name" 
                  label="Name"
                  key={`Name`}
                  value={selectedValues.name}
                  error={formError.name}
                  helperText={formError.name ? "Required field." :'' }
                  onChange={handleChange('name')}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item sx={{width: breakpointsWidth()}}>
              <FormControl fullWidth sx={{ m: 0 }}>
                <TextField 
                  id="clientNumber" 
                  label="Client Number"
                  key={`clientNumber`}
                  value={selectedValues.clientNumber}
                  onChange={handleChange('clientNumber')}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item sx={{width: breakpointsWidth()}}>
              <FormControl fullWidth sx={{ m: 0 }}>
                <TextField 
                  id="paymentNumber" 
                  label="Number for Payments"
                  key={`paymentNumber`}
                  value={selectedValues.paymentNumber}
                  onChange={handleChange('paymentNumber')}
                  variant="outlined"
                />
              </FormControl>
            </Grid>

            <Grid item sx={{width: breakpointsWidth()}}>
              <FormControl fullWidth sx={{ justifyContent: 'center', alignItems: 'center'}}>
                <FormControlLabel control={<Switch onChange={(event) => {setSelectedValues({...selectedValues, advancePayment: event.target.checked})}}/>} label="Advance Payment" />
              </FormControl>
            </Grid>

            <Grid item sx={{width: breakpointsWidth()}}>
              <FormControl fullWidth sx={{ m: 0 }}>
                <InputLabel id="demo-simple-select-label">Periodicidad</InputLabel>
                <Select
                  labelId="periodicity"
                  id="periodicity"
                  value={selectedValues.periodicity}
                  label="Periodicity"
                  size="small"
                  onChange={handleChange('periodicity')}
                >
                  <MenuItem value="Never">Never</MenuItem>
                  <MenuItem value="Days">Daily</MenuItem>
                  <MenuItem value="Weeks">Weekly</MenuItem>
                  <MenuItem value="Months">Monthly</MenuItem>
                  <MenuItem value="Years">Annual</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item sx={{width: breakpointsWidth()}}>
              <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center'}}>
                <Grid item>
                      <FormLabel id="contractType">Contrato:</FormLabel>
                </Grid>
                <Grid item >
                  <RadioGroup
                    row
                    aria-labelledby="contractType"
                    name="row-radio-buttons-group"
                    defaultValue="VAR"
                    onChange={handleChange('contractType')}
                  >
                    <FormControlLabel value="VAR" control={<Radio />} label="Variable" />
                    <FormControlLabel value="FXD" control={<Radio />} label="Fix" />
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ m: 0, width: breakpointsWidth()}}>
              <FormControl fullWidth>
                <FreqSelect units={selectedValues.periodicity} value={selectedValues.frequency} onChange={e => handleChange(e)}/>
              </FormControl>
            </Grid>

            <Grid item sx={{ m: 0, width: breakpointsWidth()}}>
              <FormControl fullWidth sx={{ m: 0, width: breakpointsWidth() }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                  label="Payments Start Date"
                  value={selectedValues.datePaymentStart}
                  PopperProps={{
                    placement: 'right-end'
                  }}
                  onChange={(newValue) => {
                    setSelectedValues({...selectedValues, datePaymentStart: newValue});
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  />  
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
      </Box>
      <SButtons buttonOptions={['SAVE', 'CANCEL']} handleButton={handleButton}/>
      {setSnackbar(submitStatus)}
    </div>
  );
}

export default AdministrationForm;


// const AdministrationForm = () => {
//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <Grid container spacing={2}>
//         <Grid item xs={6}>
//           <TextField fullWidth id="outlined-basic" label="Nombre" variant="outlined"/>
//           <TextField fullWidth id="outlined-basic" label="Número de cliente" variant="outlined"/>
//           <TextField fullWidth id="outlined-basic" label="Número para pagos" variant="outlined"/>
//         </Grid>
//         <Grid item xs={6}>
//           <Item>xs=4</Item>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }