import React, { useState, useEffect } from 'react';
import 'tachyons';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';

// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { DatePicker } from '@mui/x-date-pickers'

import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import CloudQueueTwoToneIcon from '@mui/icons-material/CloudQueueTwoTone';
import WashIcon from '@mui/icons-material/Wash';
import PetsIcon from '@mui/icons-material/Pets';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const serv = [
  {
    id: 0,
    serName: "Energía",
    "dueDate": '15/01/22',
    "amount": 110000
  },
  {
    id: 1,
    serName: "Gas",
    "dueDate": '15/01/22',
    "amount": 120000
  },
  {
    id: 2,
    serName: "Internet",
    "dueDate": "15/01/22",
    "amount": 130000
  },
  {
    id: 3,
    serName: "Acueducto",
    "dueDate": "15/01/22",
    "amount": 140000
  },
  {
    id: 4,
    serName: "Mascota",
    "dueDate": "15/01/22",
    "amount": 150000
  },
  {
    id: 5,
    serName: "Carro",
    "dueDate": "15/01/22",
    "amount": 160000
  }]

const icons = [
  <ElectricalServicesIcon sx={{ fontSize: '8vmax' }} />,
  <PropaneTankIcon sx={{ fontSize: '8vmax' }} />,
  <CloudQueueTwoToneIcon sx={{ fontSize: '8vmax' }} />,
  <WashIcon sx={{ fontSize: '8vmax' }} />,
  <PetsIcon sx={{ fontSize: '8vmax' }} />,
  <DirectionsCarIcon sx={{ fontSize: '8vmax' }} />
];

// const medioPago = [
//   'PSE',
//   'Sucursal Virtual',
//   'Cuenta Caro',
//   'Transferencia',
// ];

const sButtons = () => {
  return (
    <div className='flex flex-wrap items-end justify-center'>
      <Button className='grow shadow-5' variant="contained"
        style={{
          height: '70%',
          width: '10%',
          marginRight: '0.5vw',
          marginLeft: '0.5vw',
        }}
        sx={{
          backgroundColor: '#0E1F2F', borderColor: 'white', color: 'white',
          '&:hover': {
            color: 'white',
            backgroundColor: '#93032E',
            borderColor: 'white',
          }
        }}
      >Save</Button>
      <Button className='grow shadow-5' variant="contained"
        style={{
          height: '70%',
          width: '10%',
          marginRight: '0.5vw',
          marginLeft: '0.5vw',
        }}
        sx={{
          backgroundColor: '#0E1F2F', borderColor: 'white', color: 'white',
          '&:hover': {
            color: 'white',
            backgroundColor: '#93032E',
            borderColor: 'white',
          }
        }}
      >Cancel</Button>
    </div>
  )
}

const SelectService = (props) => {
  const [dateLimit, setDateLimit] = useState(null);
  const [datePayment, setDatePayment] = useState(null);
  const [paymentMthd, setPaymentMthd] = useState([]);
  // const [testVar, setTestVar] = useState([0]);

  useEffect(() => {
    fetch('http://localhost:3000/CheckServices')
      .then(response => response.json())
      .then(data => setPaymentMthd(data))
      .catch(() => console.log('Failed to fetch payment Mthds'))
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <h3 style={{ margin: '1%'}}> Select a service to update </h3> {/* '1vh 0vh 0.5vh 0vh' }}> Select a service to update </h3> */}   
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={serv}
        getOptionLabel={(option) => option.serName}
        sx={{ width: '100%', height: '4vh' }}
        renderInput={(params) => <TextField {...params} label="Service" />}
      />
      <div className=" ServicesForm">
        <span className="ServicesFormIcon">{icons[0]}</span>
        <div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={paymentMthd}
            renderInput={(params) => <TextField {...params} label="Medio de pago" />}
          />
          <DatePicker
            label="Fecha Límite"
            value={dateLimit}
            onChange={(newValue) => {
              setDateLimit(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="Fecha de Pago"
            value={datePayment}
            onChange={(newValue) => {
              setDatePayment(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <TextField id="outlined-basic" label="Número de comprobante" variant="outlined" />
          <Autocomplete
            disablePortal
            freeSolo
            id="combo-box-demo"
            options={paymentMthd}
            renderInput={(params) => <TextField {...params} label="Monto de pago" />}
          />
        </div>
      </div>
      {sButtons()}
    </LocalizationProvider>
  );
}

export default SelectService;