import { useRouteError } from "react-router-dom";
import { Box } from '@mui/material';

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <img
            alt="logo"
            src="/static/images/404_Image.png"
            style={{
                display: 'inline-block',
                maxWidth: '100%',
            }}
        />
    );
}
//         <h1>Oops!</h1>
//   <p>Sorry, an unexpected error has occurred.</p>
//   <p>
//     <i>{error.statusText || error.message}</i>
//   </p>