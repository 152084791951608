import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewOptions } from '../../features/optionSelected';
import TextField from '@mui/material/TextField';

const TextInputSelected = () => {
    const selectedValue = useSelector((state) => state.optionSelected);
    const [inputValue, setInputValue] = useState(selectedValue.amountpaidSelected || '');

    const dispatch = useDispatch();

    return (
        <TextField
            label="Confirmation number"
            value={inputValue}
            onChange={(event) => {
                setInputValue(event.target.value);
            }}
            onBlur={() => {
                dispatch(setNewOptions({ ...selectedValue, confirmationNmbrSelected: inputValue }));
            }}
            name="confirmationNmbrSelected"
            required={false}
            id="confirmationNmbrSelected"
            variant="outlined"
            fullWidth
        />
    );
};

export default TextInputSelected;