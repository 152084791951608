// import 'dotenv/config';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from '../../features/isLogin';
// import style from './Login.css';
import 'tachyons';
// import Box from '@mui/material/Box';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  backgroundColor: '#D8DBE2',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));

const Signin = (handleChange) => {
  const [signinData, setSigninData] = useState({
    email: '',
    password: '',
  });
  const dispatch = useDispatch();

  return (
    //<article className="br3 ba dark-gray b--white-80 mv4 w-100 w-50-m w-25-l mw6 shadow-5 center">
    <main className="pa3 navy">
      <div className="measure">
        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
          <legend className="f2 fw6 ph0 mh0">Sign In</legend>
          <div className="mt2">
            <label className="db fw6 lh-copy f6" htmlFor="email-address">Email</label>
            <input
              className="pa2 input-reset ba bg-transparent hover-bg-navy b--navy-30 hover-white w-100 ttu"
              type="email"
              name="email-address"
              id="email-address"
              onChange={(event) => { setSigninData({ ...signinData, email: event.target.value.toUpperCase() }); }}
            />
          </div>
          <div className="mv0">
            <label className="db fw6 lh-copy f6" htmlFor="password">Password</label>
            <input
              className="b pa2 input-reset ba bg-transparent hover-bg-navy b--navy-30 hover-white w-100"
              type="password"
              name="password"
              id="password"
              onChange={(event) => { setSigninData({ ...signinData, password: event.target.value }); }}
            />
          </div>
          <label className="pa0 ma0 lh-solid f6 pointer"><input type="checkbox" /> Remember me</label>
        </fieldset>
        <div className="">
          <input
            onClick={(event) => { dispatch(fetchUserData(signinData)) }}
            className="b ph3 pv2 b--white-80 white input-reset ba b--black bg-navy grow pointer f6 dib"
            type="submit"
            value="Sign in"
          />
        </div>
        <div className="lh-solid mt0">
          <p onClick={() => { handleChange() }} className="f6 link dim navy hover-dark-red db ">Register</p>
          <a href="#0" className="f6 link dim navy db">Forgot your password?</a>
        </div>
      </div>
    </main>
    //</article>
  )
}

const Signup = (handleChange) => {
  return (
    //<article className="br3 ba dark-gray b--white-80 mv4 w-100 w-50-m w-25-l mw6 shadow-5 center">
    <main className="pa3 navy">
      <div className="measure">
        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
          <legend className="f1 fw6 ph0 mh0">Sign Up</legend>
          <div className="mt3">
            <label className="db fw6 lh-solid f6" htmlFor="name">Username</label>
            <input
              className="pa2 input-reset ba bg-transparent hover-bg-navy b--navy-30 hover-white w-100"
              type="text"
              name="name"
              id="name"
              onChange={(event) => { console.log(event.target.value) }}
            />
          </div>
          <div className="mt3">
            <label className="db fw6 lh-solid f6" htmlFor="email-address">Email</label>
            <input
              className="pa2 input-reset ba bg-transparent hover-bg-navy b--navy-30 hover-white w-100 ttu"
              type="email"
              name="email-address"
              id="email-address"
              onChange={(event) => { console.log(event.target.value) }}
            />
          </div>
          <div className="mv3">
            <label className="db fw6 lh-solid f6" htmlFor="password">Password</label>
            <input
              className="b pa2 input-reset ba bg-transparent hover-bg-navy b--navy-30 hover-white w-100"
              type="password"
              name="password"
              id="password"
              onChange={(event) => { console.log(event.target.value) }}
            />
          </div>
        </fieldset>
        <div className="flex ph4 justify-between">
          <div className="">
            <input
              // onClick={this.onSubmitSignIn}
              className="b ph3 pv2 b--white-80 white input-reset ba b--black bg-navy grow pointer f6 dib"
              type="submit"
              value="Sign up"
            />
          </div>
          <div className="">
            <input
              onClick={() => { handleChange() }}
              className="b ph3 pv2 b--white-80 white input-reset ba b--black bg-navy grow pointer f6 dib"
              type="submit"
              value="Back"
            />
          </div>
        </div>
      </div>
    </main>
    //</article>
  );
}

const breakpointsWidth = () => {
  return [
    {
      xs: 0, // theme.breakpoints.up('xs')
      sm: '40vw', // theme.breakpoints.up('sm')
      md: '50vw', // theme.breakpoints.up('md')
      lg: '75vw', // theme.breakpoints.up('lg')
      xl: '80vw', // theme.breakpoints.up('xl')
    },
    {
      xs: 400, // theme.breakpoints.up('xs')
      sm: 300, // theme.breakpoints.up('sm')
      md: 300, // theme.breakpoints.up('md')
      lg: 300, // theme.breakpoints.up('lg')
      xl: 300, // theme.breakpoints.up('xl')
    }
  ]
}

const Login = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm')); // This will check for screen sizes smaller than 'sm'

  // const userData = useSelector((state) => state.userInformation.userData[0].isLoggedIn);
  const isLoggedIn = useSelector((state) => state.userInformation.userData[0].isLoggedIn);
  const [registerModule, setRegisterModule] = useState(false);

  function handleChange(changeType) {
    setRegisterModule(!registerModule);
  };

  const [imageData, setImageLink] = useState({
    // imageURL: 'https://images.unsplash.com/photo-1497377825569-02ad2f9edb81?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1489&q=80',
    // imageLink: 'https://unsplash.com/photos/Nod1nQ0i5a0',
    // imageAuthor: 'Igor Son',
    // imageAuthorLink: 'https://unsplash.com/@igorson',
  });

  useEffect(() => {
    const clientID = process.env.REACT_APP_UNSPLASH_CLIENT_ID;
    // const clientID = "7eXAwwif8Pf5q8e6leimddkzoG2TZ0Yr9gMRLdXX0d0";
    const endPoint = `https://api.unsplash.com/photos/random/?client_id=${clientID}&topics=nature,animals`
    // const endPoint = `https://api.unsplash.com/photos/random/?client_id=${clientID}`

    // fetch(endPoint)
    //   .then(response => response.json())
    //   .then(data => {
    //     const fetchImage = {
    //       imageURL: data.urls.full,
    //       imageLink: data.links.html,
    //       imageAuthor: data.user.name,
    //       imageAuthorLink: data.user.portfolio_url,
    //     };
    //     // setImageLink({...imageData, imageURL: data.urls.full})
    //     setImageLink(fetchImage);
    //   })
    //   .catch((error) => { console.error('Error: ', error); })
  }, []);

  return (
    <div className="flex" style={{ backgroundColor: '#0e1f2f' }}>
      {!matches && ( // This conditionally renders the image only if the screen size is larger than 'sm'      
        <div className='PictureBar'>
          <Box
            className="flex items-end pa3"
            style={{
              backgroundImage: `url(${imageData.imageURL})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center 40%',
            }}
            sx={{
              bgcolor: 'gray',
              color: 'white',
              width: breakpointsWidth()[0],
              height: '100vh',
            }}
          ><span style={{ 'backgroundColor': 'black', 'color': 'white' }}><a style={{ 'color': 'white' }} href={imageData.imageLink}>Photo</a> by <a style={{ 'color': 'white' }} href={imageData.imageAuthorLink}>{imageData.imageAuthor}</a> on <a style={{ 'color': 'white' }} href={'https://unsplash.com'}>Unsplash!</a> </span>
          </Box>
        </div>
      )}
      <Box
        // style={{ flex: 1 }}
        sx={{
          p: 1,
          // bgcolor: 'background.default',
          mx: "auto",
          my: "auto",
          display: 'grid',
          // gridTemplateColumns: { md: '1fr' },
          gap: 2,
        }}
      >
        <Item key={'logo'} elevation={24} sx={{
          backgroundImage: "/static/images/logoLarge.png",
          height: 240,
          width: breakpointsWidth()[1]
        }}>
          <img
            alt="logo"
            src="/static/images/logoLarge.png"
            style={{
              display: 'inline-block',
              maxWidth: '100%',
            }}
          />
        </Item>
        <Item key={'Formulaire'} elevation={24} sx={{
          height: 450,
          width: breakpointsWidth()[1]
        }}>
          {registerModule ? Signup(handleChange) : Signin(handleChange)}
        </Item>
      </Box>
    </div>
  );
}

export default Login;