import { Outlet } from "react-router-dom";
import TitleBar from '../TitleBar/TitleBar.js';
import Scroll from '../common/Scroll.js';

export default function Root() {
  return (
    <div >
      <TitleBar />
      <Scroll>
        <Outlet />
      </Scroll>
    </div>
  )
}