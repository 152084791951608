import React from 'react';
import { useSelector } from "react-redux";
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const options = {
	responsive: true,
	animation: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'top',
		},
		title: {
			display: true,
			text: 'Pagos Realizados',
		},
	},
	scales: {
		x: {
			adapters: {},
			type: 'time',
			time: {
				unit: 'month'
			}
		}
	},
};

const userDataState = (props) => {
	const data = {
		labels: props.map((data) => new Date(data.payment_date).toLocaleDateString('en-CA')),
		datasets: [{
			label: props[0].service_paid,
			data: props.map((data) => data.amount_paid),
			backgroundColor: [
				"rgba(75,192,192,1)",
				"red",
				"#50AF95",
				"#f3ba2f",
				"#2a71d0",
			],
			borderColor: "black",
			borderWidth: 2,
		}]
	}
	return data;
}

const ServicesGraph = () => {
    const theme = useTheme();
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const graphData = useSelector((state) => state.graph.graphParameters);

    // Responsive options
    const responsiveOptions = {
        ...options,
        plugins: {
            ...options.plugins,
            legend: {
                ...options.plugins.legend,
                labels: {
                    // Smaller font size on smaller screens
                    font: {
                        size: matchesSM ? 10 : 12
                    }
                }
            },
            title: {
                ...options.plugins.title,
                // Larger title on smaller screens for readability
                font: {
                    size: matchesSM ? 12 : 18
                }
            }
        },
        // Additional responsiveness configurations can go here
    };

    return (
        <div className="chart-container" style={{ position: 'relative', height: '90%', width: '100%', backgroundColor: '#FFFFFF', borderRadius: '15px' }}>
            <Line options={responsiveOptions} data={userDataState(graphData)} />
        </div>
    );
}

export default ServicesGraph;