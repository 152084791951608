// import React, { Component, useState } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

import MenuButton from './MenuButton';
import NotifButtons from './NotifButtons';
import ProfileButton from './ProfileButton';
import MobileButtons from './MobileButtons';
import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';

const mapStateToProps = (state) => {
  return {
    routes:  state
  }
}

const TitleBar = (props) => {
    const navigate = useNavigate();

    return (
     <div>
        <AppBar style={{position:"sticky", background: '#0E1F2F'}}>
          <Toolbar >
            <MenuButton />
            <p className='ttu'>{props.routes.route}</p>
            <Box sx={{ flexGrow: 1 }} >
              <IconButton
                size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => navigate('/')}
              sx={{ mr: 2 }}>
                <HomeTwoToneIcon sx={{ fontSize: '3rem', color: '#F3EE5D' }}/>
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <NotifButtons />
              <Divider sx={{ mx: 1, backgroundColor: '#D8DBE2'}} orientation="vertical" variant="middle" flexItem />
              <ProfileButton />
            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <MobileButtons />
            </Box>

          </Toolbar>
        </AppBar>
     </div>
   );
}

export default connect(mapStateToProps)(TitleBar);


// import React, { Component, useState } from 'react';
// import Box from '@mui/material/Box';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';

// import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

// import MenuButton from './MenuButton';
// import NotifButtons from './NotifButtons';
// import ProfileButton from './ProfileButton';
// import MobileButtons from './MobileButtons';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';

// const TitleBar = (props) => {
//    const [routeTitle, setRouteTitle] = useState('props');
   
//    return (
//      <div>
//        {/*<Box sx={{ flexGrow: 1 }}>*/}
//         <AppBar style={{position:"static", background: '#0E1F2F'}}>
//           <Toolbar>
//             <MenuButton />
//             <p className='ttu'>{props.route}</p>
//             <Box sx={{ flexGrow: 1 }} >
//               <HomeTwoToneIcon sx={{ fontSize: '3.5rem', color: '#F3EE5D' }}/>
//             </Box>
            
//             <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
//               <NotifButtons routeChange={setRouteTitle}/>
//               <Divider sx={{ mx: 1, backgroundColor: '#D8DBE2'}} orientation="vertical" variant="middle" flexItem />
//               <ProfileButton />
//             </Box>

//             <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
//               <MobileButtons />
//             </Box>

//           </Toolbar>
//         </AppBar>
//        {/*</Box>*/}
//      </div>
//    );
// }

// export default TitleBar;