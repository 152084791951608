import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

import MoreIcon from '@mui/icons-material/MoreVert';

import NotifButtons from './NotifButtons';
import ProfileButton from './ProfileButton';

const mobileMenuId = 'primary-search-account-menu-mobile';

const MobileButtons = () => {	
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

	const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  	};

  	const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
 	};

 	const renderMobileMenu = (
 	<Menu
	   anchorEl={mobileMoreAnchorEl}
	   anchorOrigin={{
	     vertical: 'top',
	     horizontal: 'right',
	   }}
	   id={mobileMenuId}
	   keepMounted
	   transformOrigin={{
	     vertical: 'top',
	     horizontal: 'right',
	   }}
	   open={Boolean(mobileMoreAnchorEl)}
	   onClose={handleMobileMenuClose}
 	>
 		<NotifButtons />
 		<ProfileButton />
	</Menu>
  );

	return (
		<div>
			<IconButton
           size="large"
           aria-label="show more"
           aria-controls={mobileMenuId}
           aria-haspopup="true"
           onClick={handleMobileMenuOpen}
           color="inherit">
              <MoreIcon />
         </IconButton>
         {renderMobileMenu}
		</div>
	);
}

export default MobileButtons;