import React, { useState } from 'react';
import { useSelector } from "react-redux";

import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import AccountCircle from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';

import { useDispatch } from 'react-redux'; // for testing
import { logout } from '../../features/isLogin.js';

const menuId = 'primary-search-account-menu';

const ProfileButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { name, lastname, picture, email } = useSelector((state) => state.userInformation.userData[0]);
  const dispatch = useDispatch();

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin=
      {{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      <MenuItem onClick={() => dispatch(logout())}>Sign out</MenuItem>
    </Menu>
  );

  return (
    <div>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit">
        {/*<AccountCircle />*/}
        <Avatar
          sx={{ width: '1.7rem', height: '1.7rem', backgroundColor: '#ECEBF3' }}
          alt={name}
          src={"https://robohash.org/" + email} />
      </IconButton>
      {renderMenu}
    </div>
  );
}

export default ProfileButton;