import React from 'react';
import Button from '@mui/material/Button';


function SButtons (props) {
  const buttonOptions = props.buttonOptions;
  return (
    <div className = 'flex flex-wrap items-end justify-center mt2'>
      { buttonOptions.map((value, i)=>(
        <Button className='grow shadow-5' variant="contained" 
          onClick={() => {props.handleButton([value][0])}}
          key={i}
          style={{
            height: '70%',
            width: '10%',
            marginRight: '0.5vw',
            marginLeft: '0.5vw',
          }} 
          sx={{ backgroundColor: '#0E1F2F', borderColor: 'white', color: 'white',
            '&:hover': {
              color: 'white',
              backgroundColor: '#93032E',
              borderColor: 'white',
          }}}
        >{value}</Button>
      ))}
    </div>
  )
}

export default SButtons;