import { createSlice } from "@reduxjs/toolkit";
// import { CHANGE_ROUTE } from './constants.js';

const initialState = {
	route: 'home'
}

export const routeSlice = createSlice({
	name: "route",
	initialState: initialState,
	reducers: {
		setNewRoute:(state, action) => {
			// if (action.type === CHANGE_ROUTE) {
				return Object.assign({}, state, { route: action.payload });
			// }
			// return initialState;
		},
		goToHome:(state) => {
			return Object.assign({}, state, initialState);
		},
	}
});

export const { setNewRoute, goToHome } = routeSlice.actions;
export default routeSlice.reducer;