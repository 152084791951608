import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewOptions } from '../../features/optionSelected';
import TextField from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';

// Define NumericFormatCustom outside of your component to avoid re-defining it on each render
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            // getInputElementRef={ref}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            // valueIsNumericString
            prefix="$"
        />
    );
});

const AmountInputSelected = () => {
    const selectedValue = useSelector((state) => state.optionSelected);
    const [inputValue, setInputValue] = useState(selectedValue.amountpaidSelected || '');

    const dispatch = useDispatch();

    useEffect(() => {
        setInputValue(selectedValue.amountpaidSelected || '');
    }, [selectedValue.amountpaidSelected]);

    return (
        <TextField
            label="Amount Paid"
            value={inputValue}
            onChange={(event) => {
                setInputValue(event.target.value);
            }}
            onBlur={() => {
                dispatch(setNewOptions({ ...selectedValue, amountpaidSelected: inputValue }));
            }}
            name="amountpaidSelected"
            required={true}
            id="amountpaidSelected"
            InputProps={{
                inputComponent: NumericFormatCustom,
            }}
            variant="outlined"
            fullWidth
        />
    );
};

export default AmountInputSelected;
