import React from 'react';
import { Button, Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import PropaneTankIcon from '@mui/icons-material/PropaneTank';
import CloudQueueTwoToneIcon from '@mui/icons-material/CloudQueueTwoTone';
import WashIcon from '@mui/icons-material/Wash';
import PetsIcon from '@mui/icons-material/Pets';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

const icons = (isSmallScreen) => [
  <ElectricalServicesIcon sx={{ fontSize: isSmallScreen ? '5vw' : '3vw' }} />,
  <PropaneTankIcon sx={{ fontSize: isSmallScreen ? '5vw' : '3vw' }} />,
  <CloudQueueTwoToneIcon sx={{ fontSize: isSmallScreen ? '5vw' : '3vw' }} />,
  <WashIcon sx={{ fontSize: isSmallScreen ? '5vw' : '3vw' }} />,
  <PetsIcon sx={{ fontSize: isSmallScreen ? '5vw' : '3vw' }} />,
  <DirectionsCarIcon sx={{ fontSize: isSmallScreen ? '5vw' : '3vw' }} />
];

const Indicator = ({ id, bill, dueDate, amount }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      fullWidth
      variant="outlined"
      sx={{
        height: '100%',
        borderRadius: '25px',
        borderColor: '#0E1F2F',
        color: '#0E1F2F',
        padding: 2,
        display: 'flex', // Ensure the button content is flexibly aligned
        flexDirection: 'column', // Stack children vertically
        justifyContent: 'center', // Center vertically
        alignItems: 'center', // Center horizontally
        '&:hover': {
          color: 'white',
          backgroundColor: '#93032E',
          borderColor: 'white',
        }
      }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{icons(isSmallScreen)[id]}</Grid>
        {!isSmallScreen && (
          <Grid item xs>
            <Box>
              <Typography variant="subtitle1">{bill}</Typography>
              <Typography variant="body2">{`Pago programado para el ${dueDate}`}</Typography>
              <Typography variant="body2">{amount}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Button>
  );
}

export default Indicator;
