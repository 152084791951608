import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../features/isLogin.js';
import { useNavigate } from 'react-router-dom';

const LogoutHelper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());
    navigate('/login'); 
  }, [dispatch, navigate]);

  return (
    <div>
      <p>Logging out...</p>
    </div>
  );
};

export default LogoutHelper;
