import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
import routeReducer from './features/route';
import graphReducer from './features/graphData';
import loginReducer from './features/isLogin';
import indicatorDataReducer from './features/indicatorData';
// import homePickReducer from './features/homePic';
import optionSelectedReducer from './features/optionSelected';

const logger = createLogger();
const store = configureStore({
  reducer:{
    routes: routeReducer,
    graph: graphReducer,
    userInformation: loginReducer,
    indicatorData: indicatorDataReducer,
    // picture: homePickReducer,
    optionSelected: optionSelectedReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

// Migrated to createRoot as indicated in this guide: https://reactjs.org/link/switch-to-createroot
// Use createRoot to manage the root of your app
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();