import React from 'react';
import IndicatorCard from './IndicatorCard';
// import Stack from '@mui/material/Stack';

const serv = [
	{
		"id":0,
		"serName":"Energía",
		"dueDate":'15/01/22',
		"amount": 110000
	},
	{
		"id":1,
		"serName":"Gas",
		"dueDate":'15/01/22',
		"amount":120000
	},
	{
		"id":2,
		"serName":"Internet",
		"dueDate":"15/01/22",
		"amount":130000
	},
	{
		"id":3,
		"serName":"Acueducto",
		"dueDate":"15/01/22",
		"amount":140000
	},
	{
		"id":4,
		"serName":"Mascota",
		"dueDate":"15/01/22",
		"amount":150000
	},
	{
		"id":5,
		"serName":"Carro",
		"dueDate":"15/01/22",
		"amount":160000
	}]

const IndicatorBar = ({alerts}) => {
	alerts=serv;
	return (
    	<div className="stack">{
 			alerts.map((alertServ, i) => {
				return (
					<IndicatorCard 
						key={alertServ.id} 
						id={alertServ.id} 
						bill={alertServ.serName} 
						dueDate={alertServ.dueDate} 
						amount={alertServ.amount}
					/>
				);
		})}
    	</div>
	);
}
export default IndicatorBar;


///////
/*<Stack direction="row" justifyContent="flex-end" spacing={5}  sx={{p:5}}>{
    		alerts.map((alertServ, i) => {
				{return (
					<Indicator 
						key={alertServ.id} 
						id={alertServ.id} 
						bill={alertServ.serName} 
						dueDate={alertServ.dueDate} 
						amount={alertServ.amount}
					/>
				);}
			})}
		</Stack>*/