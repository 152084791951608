import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewOptions } from '../../features/optionSelected';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

const DateSelector = ({ dateType }) => {

    const [selectedValue, setSelectedValue] = useState(useSelector((state) => state.optionSelected));
    const dispatch = useDispatch();
    let dateLabel;
    let dateState;

    switch (dateType) {
        case 'deadline':
            {
                dateLabel = 'Payment Deadline';
                dateState = 'dateLimitSelected';
                break;
            }
        case 'payment':
            {
                dateLabel = 'Payment Date';
                dateState = 'datePaymentSelected';
                break;
            }
        default:
            {
                console.log('Invalid dateType');
                break;
            }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={dateLabel}
                value={new Date(selectedValue[dateState])}
                onChange={(newValue) => {
                    const dateStr = newValue.toISOString();
                    setSelectedValue({ ...selectedValue, [dateState]: dateStr });
                    dispatch(setNewOptions({ [dateState]: dateStr }));
                }}
                renderInput={(params) => <TextField {...params} />}
                slotProps={{ textField: { fullWidth: true } }}
            // warning.js:24 The `renderInput` prop has been removed in version 6.0 of the Date and Time Pickers.
            // You can replace it with the `textField` component slot in most cases.
            // For more information, please have a look at the migration guide (https://mui.com/x/migration/migration-pickers-v5/#input-renderer-required-in-v5).
            />
        </LocalizationProvider>
    );
}

export default DateSelector;