import React from 'react';
import 'tachyons';
import SelectService from './SelectService';
import SuggestionCards from './SuggestionCards';
import ServicesGraph from './ServicesGraph';

const CheckServices = () => {
  return (
      <div className="CheckServicesContainer" >
        <div className="topServicesSection" >
          <div className="topLeftSection">
            <SelectService />
          </div>
          <div className="topRightSection">
            <SuggestionCards />
          </div>
        </div>
        <div className="bottomSection">        
          <ServicesGraph />
        </div>
      </div>
    );
}
export default CheckServices;