// This component is a dropdown menu that allows the user to select a payment Method from a list of services.
// The selected payment Method is stored in the Redux store.
// The user_id and token are retrieved from the Redux store and used to fetch the list of payment Methods.

// The list of payment Method is fetched using the fetchServicesList helper function.

// The selected payment Method is stored in the selectedValue state using the useState hook.
// The error state is used to handle errors in fetching the services list.
// The Autocomplete component is used to render the dropdown menu.
// The TextField component is used to render the input field.
// The options prop is set to the servicesList state.
// The renderInput prop is used to render the input field.
// The value prop is set to the selectedValue state.
// The onChange prop is used to update the selectedValue state when a new service is selected.
// The dispatch function is used to dispatch the setNewOptions action with the updated selectedValue state.
// The fetchGraphData action is dispatched with the selected service name to fetch the graph data for the selected service.
// The error state is rendered if there is an error in fetching the services list.
// The SelectService component is exported as a default component.
// The SelectService component is imported in the ManualInput component.

// TODO: Add proptypes
// TODO: Add tests
// TODO: Add styles
// TODO: Add error handling

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewOptions } from '../../features/optionSelected';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { fetchPaymentMethodList } from '../../helpers/fetchPaymentMethodList';

const PaymentMethodSelector = () => {

    const { user_id, token } = useSelector((state) => state.userInformation.userData[0]);
    const [selectedValue, setSelectedValue] = useState(useSelector((state) => state.optionSelected));
    const [paymentMthd, setPaymentMthd] = useState([]);
    const [error, setError] = useState(null); // Added state for error handling - temporary
    const dispatch = useDispatch();

    useEffect(() => {
        // This is an IIFE (Immediately Invoked Function Expression)
        // It's an async function that gets executed immediately
        (async () => {
            try {
                const fetchedList = await fetchPaymentMethodList(user_id, token);
                setPaymentMthd(fetchedList);
            } catch (error) {
                console.error('Failed to fetch Payment Methods:', error);
                setError('Failed to fetch Payment Methods'); // Set error state - temporary
                //Error Handling: Right now, if there's an error in fetching the services list, it's logged to the console. 
                //Depending on your application, you might want to handle this error in a user-friendly way, such as showing a message to the user or retrying the request.
            }
        })();
    }, []); //[user_id, token]); // Empty dependency array means this effect runs once after the initial render

    if (error) {
        return <div>Error: {error}</div>; // Render error message - temporary
    }

    return (
        <Autocomplete
            disablePortal
            getOptionLabel={(option) => option.name ?? option}
            id="paymentMthdSelected"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            key={`paymentMthdSelected`}
            onChange={(event, newValue) => {
                setSelectedValue({ ...selectedValue, paymentMthdSelectedId: newValue.payment_mthd_id, paymentMthdSelected: newValue.name });
                dispatch(setNewOptions({ paymentMthdSelected: newValue.name, paymentMthdSelectedId: newValue.payment_mthd_id }));
            }}
            options={paymentMthd}
            // The `renderInput` prop has been removed in version 6.0 of the Date and Time Pickers.
            // You can replace it with the `textField` component slot in most cases.
            // For more information, please have a look at the migration guide (https://mui.com/x/migration/migration-pickers-v5/#input-renderer-required-in-v5). 
            renderInput={(params) => <TextField {...params} label="Method" required={true} />} //error={formError.serviceSelected} helperText={formError.serviceSelected ? "Required field." : ''} />}
            size="small"
            // sx={{ width: '80%' }}
            value={selectedValue.paymentMthdSelected}
        />

    );
}

export default PaymentMethodSelector;